@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --background: #ffffff;
  --foreground: #0f0e12;
  --menuForeground: var(--foreground);
  --accent-1: #aeaeae;
  --accent-2: #595959;
}

body {
  color: var(--foreground);
  background: var(--background);
  font-family: var(--font-sans), Arial, Helvetica, sans-serif;
  text-rendering: optimizelegibility;
}

@layer components {
  .wrapper {
    width: 100%;
    padding-left: 3.75vw;
    padding-right: 3.75vw;
  }

  @media (min-width: 768px) {
    .wrapper {
      padding-left: 1.224vw;
      padding-right: 1.224vw;
    }
  }

  .ftext-6 {
    font-size: clamp(6px, 0.612vw, 100px);
  }

  .ftext-8 {
    font-size: clamp(8px, 0.816vw, 100px);
  }

  .ftext-9 {
    font-size: clamp(9px, 0.918vw, 100px);
  }

  .ftext-10 {
    font-size: clamp(10px, 1.02vw, 100px);
  }

  .ftext-12 {
    font-size: clamp(12px, 1.224vw, 100px);
  }

  .ftext-13 {
    font-size: clamp(13px, 1.326vw, 100px);
  }

  .ftext-14 {
    font-size: clamp(14px, 1.429vw, 100px);
  }

  .ftext-18 {
    font-size: clamp(18px, 1.837vw, 100px);
  }

  .ftext-19 {
    font-size: clamp(19px, 1.939vw, 100px);
  }

  .ftext-20 {
    font-size: clamp(20px, 2.041vw, 100px);
  }

  .ftext-24 {
    font-size: clamp(24px, 2.449vw, 100px);
  }

  .ftext-30 {
    font-size: clamp(30px, 3.061vw, 100px);
  }

  .ftext-32 {
    font-size: clamp(32px, 3.265vw, 100px);
  }

  .ftext-36 {
    font-size: clamp(36px, 3.673vw, 100px);
  }

  .ftext-56 {
    font-size: clamp(56px, 5.714vw, 500px);
  }

  .ftext-66 {
    font-size: clamp(36px, 6.735vw, 500px);
  }

  .fmtext-8 {
    font-size: clamp(8px, 2.5vw, 100px);
  }

  .fmtext-10 {
    font-size: clamp(10px, 3.125vw, 100px);
  }

  .fmtext-12 {
    font-size: clamp(12px, 3.75vw, 100px);
  }

  .fmtext-13 {
    font-size: clamp(13px, 4.063vw, 100px);
  }

  .fmtext-14 {
    font-size: clamp(14px, 4.375vw, 100px);
  }

  .fmtext-16 {
    font-size: clamp(16px, 5vw, 100px);
  }

  .fmtext-18 {
    font-size: clamp(18px, 5.625vw, 100px);
  }

  .fmtext-20 {
    font-size: clamp(20px, 6.25vw, 100px);
  }

  .fmtext-23 {
    font-size: clamp(23px, 7.188vw, 100px);
  }

  .fmtext-24 {
    font-size: clamp(24px, 7.5vw, 100px);
  }

  .fmtext-26 {
    font-size: clamp(26px, 8.125vw, 100px);
  }

  .fmtext-28 {
    font-size: clamp(26px, 8.75vw, 100px);
  }

  .fmtext-32 {
    font-size: clamp(32px, 10vw, 100px);
  }

  .fmtext-50 {
    font-size: clamp(50px, 15.625vw, 100px);
  }
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

@keyframes slideDown {
  from {
    height: 0;
  }
  to {
    height: var(--radix-collapsible-content-height);
  }
}

@keyframes slideUp {
  from {
    height: var(--radix-collapsible-content-height);
  }
  to {
    height: 0;
  }
}